import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthProvider from '../../AuthProvider';
import HomeNavBar from '../elements/HomeNavBar';
import history from '../../utils/history';
import Cookies from "universal-cookie";
import Button from '../elements/Button';

export class Servers extends Component {

    renderServers = () => {
        var servers = [];

        var servers_array = this.props.servers;
        // Order by joined
        servers_array.sort((a, b) => {
            if (a.joined && !b.joined) return -1;
            if (!a.joined && b.joined) return 1;
            return 0;
        });

        servers_array.forEach(server => {
            servers.push(
                <div className='flex relative flex-col items-center'>
                    <div className='w-full h-[150px] blur-sm bg-gradient-to-r from-cyan-500 to-blue-500' style={{
                        backgroundImage: `url('https://cdn.discordapp.com/icons/${server.id}/${server.icon}.webp')`,
                        backgroundSize: 'cover',
                    }}>

                    </div>
                    <img alt="server icon" className='w-20 top-[35px] border-white border-2  absolute blur-none rounded-full h-20' onError={(target) => {
                        target.onError = null;
                        target.target.src = process.env.PUBLIC_URL + "/img/default.png";
                    }} src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}.webp` || process.env.PUBLIC_URL + "/img/default.png"}></img>
                    <div className='mt-3 flex flex-row w-full py-3'>
                        <div>
                            <h1 className='text-lg font-bold text-white'>{server.name}</h1>
                            <span>{server.owner ? "Owner" : "Bot Admin"}</span>
                        </div>
                        {server.joined ? <Button size="md" onClick={() => {
                            history.push(`/dashboard/${server.id}`);
                        }} className='btn btn-primary  text-white ml-auto'>Settings</Button> :
                            <button onClick={() => {
                                var permissions = 8;
                                if (this.props.bot?.invite_settings) {
                                    permissions = this.props.bot?.invite_settings.invite_perms_int;
                                }
                                var url = `https://discord.com/oauth2/authorize?client_id=${this.props.bot.id}&guild_id=${server.id}&scope=applications.commands%20bot&permissions=${permissions}`;
                                if (this.props.bot?.invite_settings?.redirect) {
                                    // redirect back to /redirect?server_id=server_id use window.location
                                    url += `&redirect_uri=${window.location.origin}/redirect&response_type=code`;
                                    // Set a cookie with the server_id
                                    const cookies = new Cookies();
                                    cookies.set("redirect_server_id", `'${server.id}'`);
                                    console.log("SET COOKIE");

                                }
                                window.location.href = url;
                            }} className='btn btn-neutral  text-white ml-auto'>Invite</button>}
                    </div>
                </div>
            );

        });

        return servers;
    };
    render() {
        return (
            <AuthProvider>
                <HomeNavBar />


                <main className='bg-darkGray z-0 px-6 py-[75px] xl:p-16 xl:px-48'>
                    <div className='flex flex-col items-center gap-y-24'>
                        <h1 className='text-4xl mt-[100px] font-bold text-white'>Choose a Server</h1>

                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full gap-y-14 gap-x-14'>

                            {this.renderServers()}

                        </div>
                    </div>
                </main>

            </AuthProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    servers: state.data.servers,
    bot: state.data.bot
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Servers);