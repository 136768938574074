import server from "../utils/server";
import { setToken, getToken, isAuthenticated } from "../utils/auth";
export const setAuth = (token = null, history = null) => {
  return async (dispatch) => {
    if (token) {
      isAuthenticated(token);
      setToken(token);
      dispatch({ type: "SET_AUTH", payload: true });
      if (history) {
        history.push("/dashboard");
      }
    } else {
      const existingToken = getToken();
      if (existingToken) {
        console.log("HERE!");
        isAuthenticated(existingToken);
        try {
          const response = await server.get("/auth");
          if (response?.data?.authenticated) {
            dispatch({ type: "SET_AUTH", payload: true });
          } else {
            localStorage.removeItem('token');
            redirectToLogin();
          }
        } catch (error) {
          console.error("Error verifying authentication:", error);
          redirectToLogin();
        }
      } else {
        redirectToLogin();
      }
    }
  };
};

const redirectToLogin = () => {
  var domain = window.location.origin;


  window.location.replace(
    `${process.env.REACT_APP_API_SERVER}/auth/botpanel?returnTo=${domain}/auth/callback?redirect=${encodeURIComponent(window.location.pathname)}`
  );

};

export const recordPageView = async (location, bot_id) => {

  var url = location.pathname;
  await server.post("/stats/view", { url, bot_id }).catch(e => {

  });

};

export const setLoading = (loading) => {
  return async (dispatch, getStat) => {
    dispatch({ type: "SET_LOADING", payload: loading });
  };

};

export const getData = () => {
  return async (dispatch, getState) => {
    const response = await server.get("/client/data").catch((e) => { });

    if (response?.data) {
      if (!response?.data.bot?.color_settings) {
        response.data.bot.color_settings = {
          primary: "#f45142",
        };
      }
      dispatch({ type: "SET_DATA", payload: response.data });
    }

    if (response?.data?.user) {
      dispatch({ type: "SET_AUTH", payload: true });
    }
  };
};

export const resetModuleSettings = (module_id) => {
  return async (dispatch, getState) => {
    var serverSettings = { ...getState().data.serverSettings };
    if (!serverSettings.moduleSettings) {
      serverSettings.moduleSettings = {};
    }

    var moduleSettings = { ...serverSettings.moduleSettings };
    delete moduleSettings[module_id];
    serverSettings.moduleSettings = moduleSettings;
    dispatch({ type: "UPDATE_SERVER_SETTINGS", payload: serverSettings });
  };

};

export const updateModuleSettings = (module_id, settings) => {
  return async (dispatch, getState) => {
    var server_id = await getState().data.active_server.id;
    var serverSettings = { ...getState().data.serverSettings };
    if (!serverSettings.moduleSettings) {
      serverSettings.moduleSettings = {};
    }

    var moduleSettings = { ...serverSettings.moduleSettings };
    moduleSettings[module_id] = settings;
    serverSettings.moduleSettings = moduleSettings;
    dispatch({ type: "UPDATE_SERVER_SETTINGS", payload: serverSettings });
  };
};

export const updateServerSettingsSaveRequired = () => {
  return async (dispatch, getState) => {
    dispatch({ type: "UPDATE_SERVER_SETTINGS_SAVE_REQUIRED" });
  };
};

export const setServerData = (data) => {
  return async (dispatch, getState) => {
    data.loaded = true;
    dispatch({ type: "SET_SERVER_DATA", payload: data });
  };
};

export const setActiveServer = (server_id) => {
  return async (dispatch, getState) => {

    const activeServer = getState().data.servers.find((s) => s.id == server_id);
    console.log("SET ACTIVE SERVERS?", getState().data.servers, activeServer, server_id);
    var response = await server.get(
      "/client/server/" + server_id + "/settings",
      {}
    );

    if (activeServer) {
      dispatch({ type: "SET_ACTIVE_SERVER", payload: activeServer });
    } else {
      return window.location.replace("/servers");
    }

    if (response?.data) {
      dispatch({ type: "SET_ACTIVE_SERVER_SETTINGS", payload: response.data });
    }
  };
};
