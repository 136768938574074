/**
 * Holds the config for the axios instance
 */

import axios from 'axios';


const instance = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Credentials': true,
        "host-name": window.location.host

        // "host-name": '1029849529299779634.botghost.app'
    },
    withCredentials: true
});


// Add request interceptor to include token
instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.defaults.withCredentials = true;


export default instance;