import React, { Component } from 'react';
import { connect } from 'react-redux';
import ServerDropdown from './ServerDropdown';
import { Link } from 'react-router-dom';
import history from '../../utils/history';
import { XMarkIcon } from '@heroicons/react/20/solid';

export class SideMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidUpdate(prevProps, prevState) {

    }

    renderModules = () => {
        var moduleCategories = {};


        // Return the following 20 times
        for (var i = 0; i < this.props.modules.length; i++) {
            var module = this.props.modules[i];
            console.log(this.props, 'PROPS');
            if (this.props.module_settings && this.props.module_settings[module.id]?.enabled === false) continue;
            if (module) {
                if (!moduleCategories[module.category]) {
                    moduleCategories[module.category] = [];
                }

                moduleCategories[module.category].push(
                    <li ><Link to={`/dashboard/${this.props.server_id}/module/${module.id}`}>{module.name}</Link></li>
                );
            }
        }

        var modulesReturn = [];

        Object.keys(moduleCategories).forEach((key) => {
            modulesReturn.push(
                <>
                    <li className='menu-title font-semibold mt-3 p-0 font-bold'>{key}</li>
                    {moduleCategories[key]}
                </>
            );
        });

        return modulesReturn;
    };

    renderServers = () => {
        var servers = [];

        this.props.servers.forEach(server => {
            if (!server.joined) return;
            servers.push(
                // Name and ICON
                <option className=''>
                    <div className='!p-5'>
                        <div className='flex items-center'>
                            <img src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}.webp`} className='w-6 h-6 rounded-full'></img>
                            <span className='ml-2'>{server.name}</span>
                        </div>
                    </div>
                </option>
            );
        });
        return servers;
    };

    renderAnimation = () => {

        // animate__animated animate__faster ${this.renderAnimation()
        if (this.props.showSideMenu) {
            return "animate__slideOutLeft";
        }
        else {
            return "animate__slideInLeft";
        }
    };
    render() {
        if (this.props.showSideMenu) {
            return (
                <>
                    <section className={`} h-screen  fixed z-50 bg-menu-color w-[300px] px-3 pt-[60px] overflow-y-scroll text-white`}>
                        <div class="absolute right-0  p-3 top-0 lg:hidden">
                            <div class="hover:cursor-pointer hover:opacity-5" onClick={() => {
                                this.props.showMenu();
                            }}>
                                <XMarkIcon className='h-7 w-7' />
                            </div>
                        </div>
                        <div className='hover:cursor-pointer'>
                            <div className='avatar w-full flex justify-center mb-5' onClick={() => {
                                history.push(`/dashboard/${this.props.server_id}`);
                            }}>
                                <div className='w-24'>
                                    <img
                                        src={this.props.bot.img ? this.props.bot.img : "https://cdn.discordapp.com/embed/avatars/0.png"}
                                        alt="bot icon"
                                        class="mx-auto w-24 rounded-full mb-6"
                                        onError={(e) => {
                                            e.target.onerror = null; // Prevents infinite loop if the default image also fails
                                            e.target.src = "https://cdn.discordapp.com/embed/avatars/0.png";
                                        }}
                                    />
                                </div>
                            </div>

                        </div>

                        <div>
                            <ServerDropdown />
                        </div>


                        <ul className='menu mt-5 gap-y-1  opacity-80'>
                            <li className='menu-title font-semibold mt-3 p-0 font-bold'>General</li>
                            <li><Link to={`/dashboard/${this.props.server_id}`}>Dashboard</Link></li>
                            <li><Link to={`/dashboard/${this.props.server_id}/commands`}>Default Commands</Link></li>
                            {this.renderModules()}
                        </ul>
                    </section>
                </>
            );
        } else {
            return <></>;
        }

    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    modules: state.data.modules,
    servers: state.data.servers,
    module_settings: state.data.bot?.module_settings ? state.data.bot.module_settings : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);